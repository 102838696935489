import React, { Component } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { cloneDeep, isEmpty } from 'lodash';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../index.css";

// import "./index.css";
class UpdateUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			emailid: '',
			number: '',
			address: '',
			company: '',
			pincode: '',
			planId: '',
			planName: '',
			accountType: '',
			account: '',
			type: '',
			userType: '',
			redirectToUserManagement: false,
			planList: [],
			sendDate: '',
			expiryDate: '',
			allocatedGroupList: [],
			groupList: [],
			locationList: [],
			groupRows: [],
			groupId: '',
			locationId: '',
			groupName: '',
			locationName: '',
			groupsList: '',
			locationsList: '',
			locationRows: []
		};

	}
	componentDidMount() {
		const apiPath = this.props.app.apiPath;
		const userId = this.props.selectedUserId;
		const parent = this.props.app.userId;
		// Get Plan List
		Fetcher.get(apiPath + '/api/obd/plans/list/' + parent)
			.then(res => {
				this.setState({
					planList: res
				})
			})
			.catch(err => { console.log('Error in fetching Plans', err) });
		
		Fetcher.get(apiPath + '/api/obd/groups')
			.then(res => {
				this.setState({
					groupList: res
				})
			})
			.catch(err => { console.log('Error in fetching Group List', err) });
		
		Fetcher.get(apiPath + '/api/obd/locations')
			.then(res => {
				this.setState({
					locationList: res
				})
			})
			.catch(err => { console.log('Error in fetching Location List', err) });

		Fetcher.get(apiPath + '/api/obd/user/profile/' + userId)
			.then(res => {
				this.setState({
					name: res.name,
					emailid: res.emailid,
					number: res.number,
					address: res.address,
					company: res.company,
					pincode: res.pincode,
					planId: res.planId,
					planName: res.planName,
					userType: res.userType,
					type: res.userType==='user' ? 'User' : 'Reseller',
					accountType: res.accountType,
					account: res.accountType===0 ? 'Promotional': 'Transactional',
					expiryDate: res.expiryDate,
					groupRows: res.group,
					locationRows: res.location
				})
			})
			.catch(err => { console.log('Error in fetching Users data', err) });
	}


	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}
	hideModal = () => {
		this.setState({
			redirectToUserManagement: this.state.redirectToUserManagement
		});
	}
	addZero = (i) => {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}
	sendLaterDateChange = date => {
		this.setState({
			sendDate: date,
			expiryDate: date
		});
	};
	handleAddGroupRow = () => {
		if (this.state.groupId !== '') {
			let groupName = this.state.groupId.substring(this.state.groupId.lastIndexOf(',')+1,this.state.groupId.length);
			let groupId = this.state.groupId.substring(0,this.state.groupId.lastIndexOf(','));
			const item = {
				groupId: groupId,
				groupName: groupName
			};

			const checkGroup = obj => obj.groupName === groupName;
			if(this.state.groupRows.some(checkGroup))
			{
					Notify.notify('This Group is already selected');
					return;
			}
			//const checkGroup = obj => obj.groupId === groupId;
			/*if(this.state.groupRows.some(checkGroup))
			{
					Notify.notify('This Group is already selected');
					return;
			}*/
			
			this.state.groupRows.push(item);
			this.setState({
				groupRows: this.state.groupRows
			});
			this.state.groupsList = {
				"groupsList": this.state.groupRows
			}
		}
		else {
			Notify.notify("Please select Group");
		}
	};
	handleAddLocationRow = () => {
		if (this.state.locationId !== '') {
			let locationName = this.state.locationId.substring(this.state.locationId.lastIndexOf(',')+1,this.state.locationId.length);
			let locationId = this.state.locationId.substring(0,this.state.locationId.lastIndexOf(','));
			const item = {
				locationId: locationId,
				locationName: locationName
			};
			const checkLocation = obj => obj.locationName === locationName;
			if(this.state.locationRows.some(checkLocation))
			{
					Notify.notify('This Location is already selected');
					return;
			}
			this.state.locationRows.push(item);
			this.setState({
				locationRows: this.state.locationRows
			});
			this.state.locationsList = {
				"locationsList": this.state.locationRows
			}
		}
		else {
			Notify.notify("Please select Location");
		}
	};
	handleRemoveGroupRow = () => {
		this.setState({
			groupRows: this.state.groupRows.slice(0, -1)
		});
	};
	handleRemoveLocationRow = () => {
		this.setState({
			locationRows: this.state.locationRows.slice(0, -1)
		});
	};
	handleChangeGroupRow = idx => e => {
		const { name, value } = e.target;
		const groupRows = [...this.state.groupRows];
		groupRows[idx] = {
			[name]: value
		};
		this.setState({
			groupRows
		});
	};
	handleChangeLocationRow = idx => e => {
		const { name, value } = e.target;
		const locationRows = [...this.state.locationRows];
		locationRows[idx] = {
			[name]: value
		};
		this.setState({
			locationRows
		});
	};
	updateUser = (event) => {
		event.preventDefault();
		const state = cloneDeep(this.state);
		let date = '';
		let sendDateTime='';
		
		if(state.groupRows.length == 0)
		{
			Notify.notify('Please select atleast one group');
			return;
		}
		if(state.locationRows.length == 0)
		{
			Notify.notify('Please select atleast one location');
			return;
		}
		if(state.locationsList === '')
		{
			this.state.locationsList = {
				"locationsList": this.state.locationRows
			}
		}
		if(state.groupsList === '')
		{
			this.state.groupsList = {
				"groupsList": this.state.groupRows
			}
		}
		if (state.sendDate == null) {
			Notify.notify('Please Select Expiry Date');
			return;
		}
		if(state.sendDate === '')
		{
			sendDateTime = state.expiryDate;
		}
		else
		{
			date = state.sendDate;
			
			sendDateTime = this.addZero(date.getFullYear());
			sendDateTime += "-";
			sendDateTime += this.addZero((date.getMonth() + 1));
			sendDateTime += "-"
			sendDateTime += this.addZero(date.getDate());
			sendDateTime += " 23:59:59"
			/*sendDateTime += this.addZero(date.getHours());
			sendDateTime += ":"
			sendDateTime += this.addZero(date.getMinutes());
			sendDateTime += ":"
			sendDateTime += this.addZero(date.getSeconds());*/
		}
		const data = {
			userId: this.props.selectedUserId,
			name: state.name,
			emailid: state.emailid,
			number: state.number,
			address: state.address,
			company: state.company,
			pincode: state.pincode,
			planId: state.planId,
			accountType: state.accountType,
			userType: state.userType,
			expiryDate: sendDateTime,
			"groupRows": JSON.stringify(this.state.groupsList),
			"locationRows": JSON.stringify(this.state.locationsList)
		}
		this.saveData(data, false);

	}
	saveData = (data) => {
		return Fetcher.post(this.props.app.apiPath + '/api/obd/user/update', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				let redirectToUserManagement;
				if (res.message === 'Parameters Incorrect' || res.message === 'User Updation Failed') {
					redirectToUserManagement = false;
				} else {
					redirectToUserManagement = true;
					Notify.notify(res.message);
				}
				this.setState({
					redirectToUserManagement: redirectToUserManagement
				})

			})
			.catch(err => {
				console.log('Error in Updating Account', err)
				Notify.notify('Error in Updating Account');
			});

	}

	render() {
		if (this.state.redirectToUserManagement === true) {
			return <Redirect to={'/user-list/'} />
		}

		const groupRows = cloneDeep(this.state.groupRows);
		const allocatedGroupsTable = Array.isArray(groupRows) ? groupRows.map((item, index) => {
			return <tr key={index}>
				<td>{groupRows[index].groupName}</td>
				<td>
					<i onClick={() => this.handleRemoveGroupRow()} className="fa fa-minus-circle"></i>
				</td>
			</tr>;
		}) : [];

		const locationRows = cloneDeep(this.state.locationRows);
		const locationsTable = Array.isArray(locationRows) ? locationRows.map((item, index) => {
			return <tr key={index}>
				<td>{locationRows[index].locationName}</td>
				<td>
					<i onClick={() => this.handleRemoveLocationRow()} className="fa fa-minus-circle"></i>
				</td>
			</tr>;
		}) : [];

		const groupList = cloneDeep(this.state.groupList);
		let groupDropdown = '';
		if (!isEmpty(groupList) && (!('error' in groupList))) {
			groupDropdown = Array.isArray(groupList) ? groupList.map((obj, index) => {
				return <option value={obj.groupId+','+obj.groupName} key={`group${index}`} >{obj.groupName}</option>
			}) : []

		} else {
			groupDropdown = <option value="" >No group found</option>
		}

		const locationList = cloneDeep(this.state.locationList);
		let locationDropdown = '';
		if (!isEmpty(locationList) && (!('error' in locationList))) {
			locationDropdown = Array.isArray(locationList) ? locationList.map((obj, index) => {
				return <option value={obj.locationId+','+obj.locationName} key={`location${index}`} >{obj.locationName}</option>
			}) : []

		} else {
			locationDropdown = <option value="" >No location found</option>
		}

		let sendLaterDatepicker='';
		const filterTime = (date) => {
			const isPastTime = new Date().getTime() > date.getTime();
			return !isPastTime;
			};
		sendLaterDatepicker =
				<Col sm={4}>
					<Form.Group className="form-group required">
						<Form.Label>User Expiry</Form.Label>
							<DatePicker
								className="form-control"
								selected={this.state.expiryDate ? new Date(this.state.expiryDate) : this.state.sendDate}
								timeCaption="Time"
								minDate={new Date()}
								dateFormat="yyyy-MM-dd"
								onChange={this.sendLaterDateChange}
								filterTime={filterTime}
							/>
					</Form.Group>
				</Col>


		const planList = cloneDeep(this.state.planList);
		let planDropdown = '';
		if (!isEmpty(planList) && (!('error' in planList))) {
			planDropdown = Array.isArray(planList) ? planList.map((obj, index) => {
				return <option value={obj.planId} key={`planid${index}`} >{obj.planName}</option>
			}) : []

		} else {
			planDropdown = <option>No Plan</option>
		}
			
		return (

			<Form method="post" onSubmit={this.updateUser}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Update User</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={4}>
										<Form.Group className='form-group'>
											<Form.Label>Name</Form.Label>
											<Form.Control required name="name" type="text" placeholder="Name" onChange={e => this.setvalue(e)} value={this.state.name} />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className='form-group'>
											<Form.Label>Email ID</Form.Label>
											<Form.Control required name="emailid" type="email" placeholder="Email ID" onChange={e => this.setvalue(e)} value={this.state.emailid} />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className='form-group'>
											<Form.Label>Mobile Number</Form.Label>
											<Form.Control required name="number" type="number" placeholder="Mobile Number" onChange={e => this.setvalue(e)} value={this.state.number} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={4}>
										<Form.Group className='form-group'>
											<Form.Label>Address</Form.Label>
											<Form.Control required name="address" type="text" placeholder="Address" onChange={e => this.setvalue( e )} value={this.state.address} />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className='form-group'>
											<Form.Label>Pincode</Form.Label>
											<Form.Control required name="pincode" type="text" placeholder="Pincode" onChange={e => this.setvalue( e )} value={this.state.pincode} />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className='form-group'>
											<Form.Label>Company</Form.Label>
											<Form.Control required name="company" type="text" placeholder="Company Name" onChange={e => this.setvalue(e)} value={this.state.company} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={4}>
										<Form.Group className='form-group'>
											<Form.Label>Update Plan</Form.Label>
											<Form.Select required name="planId" onChange={e => this.setvalue(e)} as="select">
												<option value={this.state.planId}>{this.state.planName}</option>
												{planDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className='form-group'>
										<Form.Label>Update User Type</Form.Label>
											<Form.Select required name="userType" onChange={e => this.setvalue(e)} as="select">
												<option value={this.state.userType}>{this.state.type}</option>
												<option value="reseller">Reseller</option>
												<option value="user">User</option>
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className='form-group'>
											<Form.Label>Update Account Type</Form.Label>
											<Form.Select required name="accountType" onChange={e => this.setvalue(e)} as="select">
												<option value="{this.state.accountType}">{this.state.account}</option>
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									{sendLaterDatepicker}
								</Row>
								{(this.props.app.role === 'ROLE_SADMIN') && <Row>
									<Col sm={4}>
										<Form.Group className='form-group'>
											<Form.Select name="groupId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Group</option>
												{groupDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={1}>
										<i onClick={this.handleAddGroupRow} className="fa fa-plus-circle plus-icon"></i>
									</Col>
									<Col sm={4}>
										<Form.Group className='form-group'>
											<Form.Select name="locationId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Location</option>
												{locationDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={1}>
										<i onClick={this.handleAddLocationRow} className="fa fa-plus-circle plus-icon"></i>
									</Col>
								</Row>}
								{(this.props.app.role === 'ROLE_SADMIN') && <Row>
									<Col sm={4}>
										<div className="react-bootstrap-table">

											<table className="table table-striped table-bordered table-sm widgetlist-table">
												<thead>
													<tr>
														<th>Group</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{allocatedGroupsTable}
												</tbody>
											</table>
										</div>
									</Col>
									<Col sm={1}>
										
									</Col>
									<Col sm={4}>
										<div className="react-bootstrap-table">

											<table className="table table-striped table-bordered table-sm widgetlist-table">
												<thead>
													<tr>
														<th>Location</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{locationsTable}
												</tbody>
											</table>
										</div>
									</Col>
								</Row>}
								<Button className="btn-round" variant="primary" type="submit">Update User</Button>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Form>
		);
	}

}
export default UpdateUser;