import React, { Component } from 'react';
import { Button, Modal, Card, Col, Form, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { cloneDeep, isEmpty } from 'lodash';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import "./index.css";
class AllocateCLI extends Component {
	constructor(props) {

		super(props);
		this.state = {
			cliDialerList: [],
			cliAvailableList: [],
			cli: '',
			dialerId: '',
			cliId: '',
			allocatedCliId: '',
			dialerName: '',
			pilotNumber: '',
			selectedCli: '',
			showAllocateCLIModal: false,
			showPwdModal: false,
		}
	}
	componentDidMount() {
		this.getCli();
	}


	getCli = () => {
		const apiPath = this.props.app.apiPath;
		Fetcher.get(apiPath + '/api/obd/dialers/cli/list')
			.then(res => {
				this.setState({
					cliDialerList: res,
					showAllocateCLIModal: false
				})
			})
			.catch(err => { console.log('Error in fetching Dialer CLI List', err) });
	}

	getAvailableCli = (dialerId) => {
		const apiPath = this.props.app.apiPath;
		
		Fetcher.get(apiPath + '/api/obd/cli/available/'+dialerId)
			.then(res => {
				this.setState({
					cliAvailableList: res
				})
			})
			.catch(err => { console.log('Error in fetching Available CLI List', err) });
	}

	allocateCLI = (event) => {
		event.preventDefault();
		const state = cloneDeep(this.state);
		const data = {
			cliId: state.cliId,
			allocatedCliId: this.state.allocatedCliId,
			dialerId: this.state.dialerId
		}
		return Fetcher.post(this.props.app.apiPath + '/api/obd/change/cli', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				if (res.message === 'Parameters Incorrect') {
					Notify.notify(res.message);
				} else {
					Notify.notify(res.message);
				}
				this.getCli();
			})
			.catch(err => {
				console.log('Error in Changing CLI', err)
				Notify.notify('Error in Changing CLI');
			});
	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	setMessageShow = (status) => {
		this.setState({
			showAllocateCLIModal: false,
			showPwdModal: false,
		});
	}

	showAllocateCLIModal = (dialerId, allocatedCliId) => {
		this.setState({
			showAllocateCLIModal: true,
			
		});
		this.state.dialerId = dialerId;
		this.state.allocatedCliId = allocatedCliId;
		this.getAvailableCli(dialerId);
	}

	render() {

		const availableCliList = cloneDeep(this.state.cliAvailableList);
		let availableCliDropdown = '';
		if (!isEmpty(availableCliList) && (!('error' in availableCliList))) {
			availableCliDropdown = Array.isArray(availableCliList) ? availableCliList.map((obj, index) => {
				return <option value={obj.cliId} key={`cli${index}`} >{obj.cli}</option>
			}) : []

		} else {
			availableCliDropdown = <option value="" >No ID found</option>
		}

		const cliDialerList = this.state.cliDialerList;
		var columns;
			columns = [
				{
					dataField: 'dialerId',
					text: 'Id',
					sort: true,
					searchable: true,
					hidden: true
				},
				{
					dataField: 'cli',
					text: 'CLI',
					sort: true,
					searchable: true
				},
				{
					dataField: 'dialerName',
					text: 'Dialer Name',
					sort: true,
					searchable: true
				}, {
					dataField: 'pilotNumber',
					text: 'Pilot Number',
					sort: true,
					searchable: true
				},
				{
					dataField: 'action',
					text: 'Action',
					sort: false,
					searchable: false
				}];
		const cliList = Array.isArray(cliDialerList) ? cliDialerList.map((cli, index) => {
				let action = [<a className="btn btn-sm btn-primary" id="edit-btn" key={`${index}-change-cli`} onClick={(e) => { this.showAllocateCLIModal(cli.dialerId, cli.allocatedCliId) }} href="#">Change CLI</a>];
				return {
					dialerId: cli.dialerId,
					cli: cli.cli,
					dialerName: cli.dialerName,
					pilotNumber: cli.pilotNumber==0 ? null : cli.pilotNumber,
					allocatedCliId: cli.allocatedCliId,
					action: action
				};
		}) : []
		const { SearchBar, ClearSearchButton } = Search;

		const defaultSorted = [{
			dataField: 'dialerId',
			order: 'desc'
		}];

		const paginationOption = {
			custom: true,
			totalSize: cliList.length,
			sizePerPage: 10,
			sizePerPageList: [10, 25, 50, 100]
		};

		return (
			<Card>
				{<Card.Header>CLI List</Card.Header>}

				<Card.Body>
					<div className="cli-list-table-cont">
						{<PaginationProvider pagination={paginationFactory(paginationOption)}>
							{
								({
									paginationProps,
									paginationTableProps
								}) => (
									<ToolkitProvider
										keyField="dialerId"
										data={cliList}
										columns={columns}
										search
										bootstrap4

									>
										{
											props => (
												<div className="">
													Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
													<span className="search-cont"><SearchBar  {...props.searchProps} /></span>
													<ClearSearchButton className="btn-primary btn-round" {...props.searchProps} />
													{/*<span>&nbsp;&nbsp;&nbsp;</span><span className="float-none avail-credit-cont"><span className="credits-avail">Available Credits: {this.state.adminCredits}</span></span>*/}
													{/*<Link className="float-right btn btn-default btn-primary" to={`${this.props.app.urlPrefix}/create-user`}>Add User</Link>*/}
													<hr />
													<BootstrapTable defaultSorted={defaultSorted} bordered={true} hover condensed wrapperClasses="table-responsive" classes="cliDialerList-table"  {...props.baseProps}  {...paginationTableProps} />
													<PaginationListStandalone {...paginationProps} />
													
												</div>
											)
										}
									</ToolkitProvider>
								)
							}
						</PaginationProvider>}
					</div>
					<Modal size="md" show={this.state.showAllocateCLIModal} onHide={() => this.setMessageShow(false)}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Change CLI
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form method="post" onSubmit={this.allocateCLI}>
								<Row>
									<Col sm={4}>
										<Form.Group className='form-group'>
											<Form.Select required name="cliId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select CLI</option>
												{availableCliDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">Change CLI</Button>
							</Form>
						</Modal.Body>
					</Modal>
				</Card.Body>
			</Card>

		);
	}
}

export default AllocateCLI;