import React from 'react';
import { Link } from 'react-router-dom'
import './index.css';
import { Accordion } from 'react-bootstrap';
const SideNav = (props) => {
	let urlPrefix;
	if (props.app.mode === 'dev') {
		urlPrefix = '/build';
	} else {
		urlPrefix = '';
	}
	return (
		<div className="sidebar">
			<div className="sidebar__background"></div>
			<div className="scrollbar-container sidebar-nav">
			<a className="navbar-brand" href={props.app.siteUrl}>
				{props.app.logo && <img className="navbar-brand-full" src={props.app.logo} alt="Logo" />}
			</a>
				{props.app.role === 'ROLE_USER' && <ul className="nav">
					<li className="nav-item"><Link className="nav-link active" to="/" aria-current="page"><i className="mr-2 fa fa-dashboard"></i> Dashboard</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/credits-history`}><i className="mr-2 fa fa-history"></i> Credits History</Link></li>
					<li className='nav-group'>
						<Accordion defaultActiveKey="1">
							<Accordion.Item eventKey="1">
								<Accordion.Header><i className="mr-2 fa fa-plus-circle"></i>Voice Campaigns</Accordion.Header>
								<Accordion.Body>
								<ul className='nav-group-items'>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-voice-campaign`}><i className="mr-2 fa fa-plus"></i>Compose Campaign</Link></li>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-campaign-summary`}><i className="mr-2 fa fa-list"></i>Live Summary</Link></li>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/historical-voice-campaign-summary`}><i className="mr-2 fa fa-list"></i>Historical Summary</Link></li>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/schedule-voice-campaign-summary`}><i className="mr-2 fa fa-list"></i>Schedule Summary</Link></li>
								</ul>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="3">
								<Accordion.Header><i className="mr-2 fa fa-file"></i>Reports</Accordion.Header>
								<Accordion.Body>
								<ul className='nav-group-items'>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/download-report`}><i className="mr-2 fa fa-download"></i>Download Report</Link></li>
								</ul>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="2">
								<Accordion.Header><i className="mr-2 fa fa-ban"></i>Voice Blacklist</Accordion.Header>
								<Accordion.Body>
								<ul className='nav-group-items'>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-voice-blacklist`}><i className="mr-2 fa fa-plus"></i>Add to Blacklist</Link></li>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/bulk-voice-blacklist`}><i className="mr-2 fa fa-plus"></i>Add Bulk Blacklist</Link></li>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-blacklist`}><i className="mr-2 fa fa-list"></i>View Blacklist</Link></li>
								</ul>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="5">
								<Accordion.Header><i className="mr-2 fa fa-music"></i> Voice Library</Accordion.Header>
								<Accordion.Body>
								<ul className='nav-group-items'>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-voice-files`}><i className="mr-2 fa fa-plus"></i>Add Voice File</Link></li>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-files`}><i className="mr-2 fa fa-list"></i>View Voice Files</Link></li>
								</ul>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</li>
				</ul>}

				{(props.app.role === 'ROLE_ADMIN') && <ul className="nav">
					<li className="nav-item"><Link className="nav-link active" to="/" aria-current="page"><i className="mr-2 fa fa-dashboard"></i> Dashboard</Link></li>
					<li className='nav-group'>
						<Accordion defaultActiveKey="0">
							<Accordion.Item eventKey="0">
								<Accordion.Header><i className="mr-2 fa fa-user"></i>Manage Users</Accordion.Header>
								<Accordion.Body>
								<ul className='nav-group-items'>							
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/create-user`}><i className="mr-2 fa fa-plus"></i>Create User </Link></li>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/user-list`}><i className="mr-2 fa fa-list"></i>User List</Link></li>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/credits-history`}><i className="mr-2 fa fa-history"></i>Credits History</Link></li>
								</ul>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="4">
								<Accordion.Header><i className="mr-2 fa fa-wifi"></i>Voice Plans</Accordion.Header>
								<Accordion.Body>
								<ul className='nav-group-items'>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-voice-plan`}><i className="mr-2 fa fa-plus"></i>Add Voice Plan</Link></li>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-plans`}><i className="mr-2 fa fa-list"></i>View Voice Plans</Link></li>
								</ul>
								</Accordion.Body>
							</Accordion.Item>
							
						</Accordion>
					</li>
				</ul>}

				{(props.app.role === 'ROLE_SADMIN') && <ul className="nav">
				<li className="nav-item"><Link className="nav-link active" to="/" aria-current="page"><i className="mr-2 fa fa-dashboard"></i> Dashboard</Link></li>
				<li className='nav-group'>
					<Accordion defaultActiveKey="0">
						<Accordion.Item eventKey="0">
							<Accordion.Header><i className="mr-2 fa fa-user"></i>Manage Users</Accordion.Header>
							<Accordion.Body>
							<ul className='nav-group-items'>							
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/create-user`}><i className="mr-2 fa fa-plus"></i>Create User </Link></li>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/user-list`}><i className="mr-2 fa fa-list"></i>User List</Link></li>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/credits-history`}><i className="mr-2 fa fa-history"></i>Credits History</Link></li>
							</ul>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="1">
							<Accordion.Header><i className="mr-2 fa fa-phone"></i>Manage CLI</Accordion.Header>
							<Accordion.Body>
							<ul className='nav-group-items'>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/upload-cli`}><i className="mr-2 fa fa-upload"></i>Upload CLI</Link></li>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/allocate-cli`}><i className="mr-2 fa fa-tasks"></i>Allocate CLI </Link></li>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/archived-cli`}><i className="mr-2 fa fa-archive"></i>Archived CLI List </Link></li>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/cli-list`}><i className="mr-2 fa fa-list"></i>CLI List</Link></li>
							</ul>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="6">
							<Accordion.Header><i className="mr-2 fa fa-users"></i>Manage Groups</Accordion.Header>
							<Accordion.Body>
							<ul className='nav-group-items'>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/create-group`}><i className="mr-2 fa fa-plus"></i>Create Group </Link></li>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/group-list`}><i className="mr-2 fa fa-list"></i>Group List</Link></li>
							</ul>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="7">
							<Accordion.Header><i className="mr-2 fa fa-map-marker"></i>Manage Locations</Accordion.Header>
							<Accordion.Body>
							<ul className='nav-group-items'>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/create-location`}><i className="mr-2 fa fa-plus"></i>Create Location </Link></li>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/location-list`}><i className="mr-2 fa fa-list"></i>Location List</Link></li>
							</ul>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="2">
							<Accordion.Header><i className="mr-2 fa fa-pencil"></i>Manage Dialers</Accordion.Header>
							<Accordion.Body>
							<ul className='nav-group-items'>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/create-dialer`}><i className="mr-2 fa fa-plus"></i>Create Dialer </Link></li>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/dialer-list`}><i className="mr-2 fa fa-list"></i>Dialer List</Link></li>
							</ul>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="3">
							<Accordion.Header><i className="mr-2 fa fa-phone"></i>Manage Trunks</Accordion.Header>
							<Accordion.Body>
							<ul className='nav-group-items'>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/create-trunk`}><i className="mr-2 fa fa-plus"></i>Create Trunk </Link></li>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/trunk-list`}><i className="mr-2 fa fa-list"></i>Trunk List</Link></li>
							</ul>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="4">
							<Accordion.Header><i className="mr-2 fa fa-wifi"></i>Voice Plans</Accordion.Header>
							<Accordion.Body>
							<ul className='nav-group-items'>
						<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-voice-plan`}><i className="mr-2 fa fa-plus"></i>Add Voice Plan</Link></li>
						<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-plans`}><i className="mr-2 fa fa-list"></i>View Voice Plans</Link></li>
							</ul>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="5">
							<Accordion.Header><i className="mr-2 fa fa-music"></i>Voice Library</Accordion.Header>
							<Accordion.Body>
							<ul className='nav-group-items'>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-files`}><i className="mr-2 fa fa-check"></i>Prompts for Approval</Link></li>
							</ul>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</li>	
				</ul>}

			</div>
		</div>
	);

}

export default SideNav;
