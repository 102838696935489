import React, { Component } from 'react';
import { Form, Row, Col, Card, Button } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import { cloneDeep, isEmpty } from 'lodash';
import "./index.css";

class AddVoicePlan extends Component {
	constructor(props) {
		super(props);
		let sitePath;
		let filePath;
		
		const protocol = window.location.protocol;
		const slashes = protocol.concat("//");
		sitePath = slashes.concat(window.location.hostname);
		this.state = {
			userId: props.app.userId,
			apiPath: props.app.apiPath,
			sitePath: sitePath,
			filePath: filePath,
			planAdded: false,
			pulseList: [],
			pulseDuration: '',
			planName: '',
			pulsePrice: ''
		}

	}

	componentDidMount() {
		this.getPulses();
	}

	getPulses = () => {
		const apiPath = this.props.app.apiPath;
		Fetcher.get(apiPath + '/api/obd/pulse')
			.then(res => {
				this.setState({
					pulseList: res
				})
			})
			.catch(err => { console.log('Error in fetching Pulse List', err) });
	}

	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
	}


	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	setMessageShow = (status) => {
		this.setState({
		});
	}

	// Add Plan
	handleAddVoicePlanSubmit = (event) => {
		event.preventDefault();
		const formData = cloneDeep(this.state);
		console.log(formData);

		const data = {
			"userId": formData.userId,
			"planName": formData.planName,
			"pulseDuration": formData.pulseDuration,
			"pulsePrice": formData.pulsePrice
		};

		return Fetcher.post(formData.apiPath + '/api/obd/plan', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				
				let planAdded = false;
				if (res.message === 'Parameters Incorrect') {
					Notify.notify(res.message);
				} else {
					Notify.notify(res.message);
					planAdded = true;
				}
				this.setState({
					planAdded: planAdded
				})
			})
			.catch(err => {
				console.log('Error in Creating Voice Plan', err);
				Notify.notify('Error in Creating Voice Plan');
			});
	}

	addZero = (i) => {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}

	render() {
		if (this.state.planAdded === true) {
			return <Redirect to='/voice-plans' />
		}
		let submitButtonlabel = 'Add Plan';
		const pulseList = cloneDeep(this.state.pulseList);

		let pulseDropdown = '';
		if (!isEmpty(pulseList) && (!('error' in pulseList))) {
			pulseDropdown = Array.isArray(pulseList) ? pulseList.map((obj, index) => {
				return <option value={obj.pulseDuration} key={`pulseid${index}`} >{obj.pulseDuration} sec</option>
			}) : <option value="" >No Pulse found</option>

		} else {
			pulseDropdown = <option value="" >No Pulse found</option>
		}

		return (

			<Form className="add-campaign-form" onSubmit={(e) => this.handleAddVoicePlanSubmit(e)}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Add Voice Plan</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={4}>
										<Form.Group className='form-group' controlId="plan-name">
											<Form.Label>Plan Name</Form.Label>
											<Form.Control required name="planName" onChange={e => this.setvalue(e)} value={this.state.planName} type="text" placeholder="Plan Name" />
										</Form.Group>
									</Col>
								
									<Col sm={4}>
										<Form.Group className='form-group' controlId="sender-id">
											<Form.Label>Pulse Duration</Form.Label>
											<Form.Select required name="pulseDuration" value={this.state.pulseDuration} onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Pulse Duration</option>
												{pulseDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className='form-group' controlId="pulse-price">
											<Form.Label>Pulse Price(Paisa)</Form.Label>
											<Form.Control required name="pulsePrice" onChange={e => this.setvalue(e)} value={this.state.pulsePrice} type="text" placeholder="Pulse Price" />
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">{submitButtonlabel}</Button>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Form>
		);
	}
}

export default AddVoicePlan;