import React from 'react';
import AddVoiceCampaign from './AddVoiceCampaign';
import VoiceCampaignInfo from './VoiceCampaignInfo';
import ListVoiceCampaign from './ListVoiceCampaign';
import ListHistoricalVoiceCampaign from './ListHistoricalVoiceCampaign';
import ListScheduleVoiceCampaign from './ListScheduleVoiceCampaign';
import AddVoiceFile from './AddVoiceFile';
import ListVoiceFiles from './ListVoiceFiles';
import AddVoicePlan from './AddVoicePlan';
import ListVoicePlans from './ListVoicePlans';
import AddVoiceBlacklist from './AddVoiceBlacklist';
import ListVoiceBlacklist from './ListVoiceBlacklist';
import UploadBlacklistFile from './UploadBlacklistFile';
import { Route, Switch } from "react-router-dom";
const Campaign = (props) => {
	const urlPrefix = props.app.urlPrefix;
	return (

		<div className="">
			<Switch>
				<Route path={`${urlPrefix}/add-voice-campaign`} ><AddVoiceCampaign app={props.app} /></Route>
				<Route path={`${urlPrefix}/voice-campaign-summary`} ><ListVoiceCampaign app={props.app} /></Route>
				<Route path={`${urlPrefix}/historical-voice-campaign-summary`} ><ListHistoricalVoiceCampaign app={props.app} /></Route>
				<Route path={`${urlPrefix}/schedule-voice-campaign-summary`} ><ListScheduleVoiceCampaign app={props.app} /></Route>
				<Route path={`${urlPrefix}/add-voice-files`} ><AddVoiceFile app={props.app} /></Route>
				<Route path={`${urlPrefix}/voice-files`} ><ListVoiceFiles app={props.app} /></Route>
				<Route path={`${urlPrefix}/add-voice-plan`} ><AddVoicePlan app={props.app} /></Route>
				<Route path={`${urlPrefix}/voice-plans`} ><ListVoicePlans app={props.app} /></Route>
				<Route path={`${urlPrefix}/add-voice-blacklist`} ><AddVoiceBlacklist app={props.app} /></Route>
				<Route path={`${urlPrefix}/bulk-voice-blacklist`} ><UploadBlacklistFile app={props.app} /></Route>
				<Route path={`${urlPrefix}/voice-blacklist`} ><ListVoiceBlacklist app={props.app} /></Route>
				<Route path={`${urlPrefix}/voice-campaign-info/:campaignId`} render={(routeprops) => (
					<VoiceCampaignInfo campaignId={routeprops.match.params.campaignId} app={props.app} {...routeprops} />)
					} />
			</Switch>
		</div>

	);
}

export default Campaign;
