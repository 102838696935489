import React, { Component } from 'react';
import { Button, Card, Col, Form, Row, Modal } from 'react-bootstrap';
import { cloneDeep, isEmpty, isNull } from 'lodash';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Notify } from "../../../Helpers/notification.js";

import "../index.css";
class CreateUser extends Component {
	constructor(props) {
		super(props);
		this.logoInput = React.createRef();
		this.state = {
			username: '',
			password: '',
			planId: '',
			confirmPassword: '',
			name: '',
			emailid: '',
			number: '',
			address: '',
			company: '',
			pincode: '',
			credits: '',
			domain: '',
			channels: 0,
			parentChannels: 0,
			userChannels: 0,
			logoInput: this.logoInput,
			logo: '',
			redirectFlag: false,
			userType: '',
			planList: [],
			accountType: '',
			parentAccount: '',
			userId: props.app.userId,
			apiPath: props.app.apiPath,
			channelsAvailable: '',
			sendDate: new Date(),
			logoDomain: false,
			locationList: [],
			groupList: [],
			locationId: '',
			groupId: '',
			groupName: '',
			groupRows: [],
			locationRows: [],
			groupsList: '',
			locationsList: ''
		};

	}
	componentDidMount() {
		const apiPath = this.state.apiPath;
		const userId = this.state.userId;
		const parent = this.props.app.userId;
		Fetcher.get(apiPath + '/api/obd/user/profile/' + userId)
			.then(res => {
				this.setState({
					parentChannels: res.channels,
					parentAccount: res.accountType
				})
			})
			.catch(err => { console.log('Error in fetching Users data', err) });

		// Get Plan List
		Fetcher.get(apiPath + '/api/obd/plans/list/' + userId)
			.then(res => {
				this.setState({
					planList: res
				})
			})
			.catch(err => { console.log('Error in fetching Plans', err) });

		Fetcher.get(apiPath + '/api/obd/locations')
			.then(res => {
				this.setState({
					locationList: res
				})
			})
			.catch(err => { console.log('Error in fetching Location List', err) });
		
		Fetcher.get(apiPath + '/api/obd/groups')
			.then(res => {
				this.setState({
					groupList: res
				})
			})
			.catch(err => { console.log('Error in fetching Group List', err) });
	}

	sendLaterDateChange = date => {
		this.setState({
			sendDate: date
		});
	};
	
	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}
	addZero = (i) => {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}
	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
	}
	hideModal = () => {
		this.setState({
			showModal: false,
			redirectFlag: this.state.redirectFlag
		});
	}

	handleAddGroupRow = () => {
		if (this.state.groupId !== '') {
			let groupName = this.state.groupId.substring(this.state.groupId.lastIndexOf(',')+1,this.state.groupId.length);
			let groupId = this.state.groupId.substring(0,this.state.groupId.lastIndexOf(','));
			const item = {
				groupId: groupId,
				groupName: groupName
			};
			const checkGroup = obj => obj.groupId === groupId;
			if(this.state.groupRows.some(checkGroup))
			{
					Notify.notify('This Group is already selected');
					return;
			}
			this.state.groupRows.push(item);
			this.setState({
				groupRows: this.state.groupRows
			});
			this.state.groupsList = {
				"groupsList": this.state.groupRows
			}
		}
		else {
			Notify.notify("Please select Group");
		}
	};

	handleAddLocationRow = () => {
		if (this.state.locationId !== '') {
			let locationName = this.state.locationId.substring(this.state.locationId.lastIndexOf(',')+1,this.state.locationId.length);
			let locationId = this.state.locationId.substring(0,this.state.locationId.lastIndexOf(','));
			const item = {
				locationId: locationId,
				locationName: locationName
			};
			const checkLocation = obj => obj.locationId === locationId;
			if(this.state.locationRows.some(checkLocation))
			{
					Notify.notify('This Location is already selected');
					return;
			}
			this.state.locationRows.push(item);
			this.setState({
				locationRows: this.state.locationRows
			});
			this.state.locationsList = {
				"locationsList": this.state.locationRows
			}
		}
		else {
			Notify.notify("Please select Location");
		}
	};

	handleRemoveGroupRow = () => {
		this.setState({
			groupRows: this.state.groupRows.slice(0, -1)
		});
	};

	handleRemoveLocationRow = () => {
		this.setState({
			locationRows: this.state.locationRows.slice(0, -1)
		});
	};

	handleChangeGroupRow = idx => e => {
		const { name, value } = e.target;
		const groupRows = [...this.state.groupRows];
		groupRows[idx] = {
			[name]: value
		};
		this.setState({
			groupRows
		});
	};

	handleChangeLocationRow = idx => e => {
		const { name, value } = e.target;
		const locationRows = [...this.state.locationRows];
		locationRows[idx] = {
			[name]: value
		};
		this.setState({
			locationRows
		});
	};


	registerUser = (event) => {
		event.preventDefault();
		const state = cloneDeep(this.state);
		if (state.confirmPassword !== state.password) {
			alert('Password & Confim Password don\'t match');
			return;
		}
		if (state.password.length < 8) {
			alert('Password cannot be less than 8 characters');
			return;
		}
		
		const date = state.sendDate;
		let sendDateTime = this.addZero(date.getFullYear());
		sendDateTime += "-";
		sendDateTime += this.addZero((date.getMonth() + 1));
		sendDateTime += "-"
		sendDateTime += this.addZero(date.getDate());
		sendDateTime += " 23:59:59"
		/*sendDateTime += this.addZero(date.getHours());
		sendDateTime += ":"
		sendDateTime += this.addZero(date.getMinutes());
		sendDateTime += ":"
		sendDateTime += this.addZero(date.getSeconds());*/
		
		var logo = null;
		var fd = new FormData();
		
		if(this.state.logoDomain)
		{
			logo = state.logoInput.current.files[0];
			fd.append('username', state.username);
			fd.append('password', state.password);
			fd.append('planId', state.planId);
			fd.append('name', state.name);
			fd.append('emailid', state.emailid);
			fd.append('number', state.number);
			fd.append('address', state.address);
			fd.append('company', state.company);
			fd.append('pincode', state.pincode);
			fd.append('logo', logo);
			fd.append('domain', state.domain);
			fd.append('parent', this.props.app.userId);
			fd.append('accountType', state.accountType);
			fd.append('userType', state.userType);
			fd.append('expiryDate', sendDateTime);
			fd.append('groupRows', JSON.stringify(this.state.groupsList));
			fd.append('locationRows', JSON.stringify(this.state.locationsList));
			this.saveDataWithLogo(fd);
		}
		else
		{
			const data = {
				username: state.username,
				password: state.password,
				planId: state.planId,
				name: state.name,
				emailid: state.emailid,
				number: state.number,
				address: state.address,
				company: state.company,
				pincode: state.pincode,
				parent: this.props.app.userId,
				accountType: state.accountType,
				userType: state.userType,
				expiryDate: sendDateTime,
				"groupRows": JSON.stringify(this.state.groupsList),
				"locationRows": JSON.stringify(this.state.locationsList)
			}
			this.saveData(data);
		}
	}
	saveDataWithLogo = (fd) => {
		return Fetcher.post(this.props.app.apiPath + '/api/obd/register1', { method: 'POST', body: fd })
			.then(res => {
				Notify.notify(res.message);
				if(res.message === 'User Created Successfully')
				{
					this.setState({
						redirectFlag: true
					})
				}
			})
			.catch(err => {
				console.log('Error in Registering Account', err)
				Notify.notify('Error in Registering Account');
			});

	}
	saveData = (data) => {
		return Fetcher.post(this.props.app.apiPath + '/api/obd/register', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				if(res.message === 'User Created Successfully')
				{
					this.setState({
						redirectFlag: true
					})
				}
			})
			.catch(err => {
				console.log('Error in Registering Account', err)
				Notify.notify('Error in Registering Account');
			});

	}

	setMessageShow = (status) => {
		this.setState({
			showModal: false,
		});
	}

	render() {
		if (this.state.redirectFlag === true) {
			return <Redirect to={'/user-list/'} />
		}

		const groupRows = cloneDeep(this.state.groupRows);
		const groupsTable = Array.isArray(groupRows) ? groupRows.map((item, index) => {
			return <tr key={index}>
				<td>{groupRows[index].groupName}</td>
				<td>
					<i onClick={() => this.handleRemoveGroupRow()} className="fa fa-minus-circle"></i>
				</td>
			</tr>;
		}) : [];

		const locationRows = cloneDeep(this.state.locationRows);
		const locationsTable = Array.isArray(locationRows) ? locationRows.map((item, index) => {
			return <tr key={index}>
				<td>{locationRows[index].locationName}</td>
				<td>
					<i onClick={() => this.handleRemoveLocationRow()} className="fa fa-minus-circle"></i>
				</td>
			</tr>;
		}) : [];

		const locationList = cloneDeep(this.state.locationList);
		let locationDropdown = '';
		if (!isEmpty(locationList) && (!('error' in locationList))) {
			locationDropdown = Array.isArray(locationList) ? locationList.map((obj, index) => {
				return <option value={obj.locationId+','+obj.locationName} key={`location${index}`} >{obj.locationName}</option>
			}) : []

		} else {
			locationDropdown = <option value="" >No location found</option>
		}

		const groupList = cloneDeep(this.state.groupList);
		let groupDropdown = '';
		if (!isEmpty(groupList) && (!('error' in groupList))) {
			groupDropdown = Array.isArray(groupList) ? groupList.map((obj, index) => {
				return <option value={obj.groupId+','+obj.groupName} key={`group${index}`} >{obj.groupName}</option>
			}) : []

		} else {
			groupDropdown = <option value="" >No group found</option>
		}


		const planList = cloneDeep(this.state.planList);
		let planDropdown = '';
		if (!isEmpty(planList) && (!('error' in planList))) {
			planDropdown = Array.isArray(planList) ? planList.map((obj, index) => {
				return <option value={obj.planId} key={`planid${index}`} >{obj.planName}</option>
			}) : []

		} else {
			planDropdown = <option value="" >No Plan found</option>
		}
		const filterTime = (date) => {
			const isPastTime = new Date().getTime() > date.getTime();
			return !isPastTime;
			};
			const sendLater = this.state.sendLater;
			let sendLaterDatepicker = '';
			sendLaterDatepicker = 
			<Col sm={12
			
			
			}>
				<Form.Group  className="form-group expiry required control-label">
					<Form.Label>User Expiry</Form.Label>
					<DatePicker
						className="form-control"
						selected={this.state.sendDate}
						minDate={new Date()}
						timeCaption="Time"
						dateFormat="yyyy-MM-dd"
						onChange={this.sendLaterDateChange}
						filterTime={filterTime}
					/>
				</Form.Group>
			</Col>
			
		return (
			<Form method="post" onSubmit={this.registerUser}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Create New User</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={4}>
										<Form.Group  className="form-group required control-label">
											<Form.Control required name="username" type="text" placeholder="Username" onChange={e => this.setvalue(e)} value={this.state.username} autoComplete="off" />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group  className="form-group required control-label">
											<Form.Control required name="password" type="password" placeholder="Password" onChange={e => this.setvalue(e)} value={this.state.password} autoComplete="new-password"/>
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group  className="form-group required control-label">
											<Form.Control required name="confirmPassword" type="password" placeholder="Confirm Password" onChange={e => this.setvalue(e)} value={this.state.confirmPassword} />
										</Form.Group>
									</Col>
								
									<Col sm={4}>
										<Form.Group  className="form-group required control-label">
											<Form.Control required name="name" type="text" placeholder="Name" onChange={e => this.setvalue(e)} value={this.state.name} />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group  className="form-group required control-label">
											<Form.Control required name="emailid" type="email" placeholder="Email ID" onChange={e => this.setvalue(e)} value={this.state.emailid} />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group  className="form-group required control-label">
											<Form.Control required name="number" type="number" placeholder="Mobile Number" onChange={e => this.setvalue(e)} value={this.state.number} />
										</Form.Group>
									</Col>
								
									<Col sm={4}>
										<Form.Group  className="form-group required control-label">
											<Form.Control required name="company" type="text" placeholder="Company Name" onChange={e => this.setvalue(e)} value={this.state.company} />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group  className="form-group required control-label">
											<Form.Control required name="address" type="text" placeholder="Address" onChange={e => this.setvalue(e)} value={this.state.address} />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group  className="form-group required control-label">
											<Form.Control required name="pincode" type="number" placeholder="Pincode" onChange={e => this.setvalue(e)} value={this.state.pincode} />
										</Form.Group>
									</Col>
								
									<Col sm={4}>
										<Form.Group  className="form-group required control-label">
											<Form.Select className="dd" required name="userType" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Customer Type</option>
												<option value="reseller">Reseller</option>
												<option value="user">User</option>
											</Form.Select>
										</Form.Group>
									</Col>
									{(this.props.app.role === 'ROLE_SADMIN') && <Col sm={4}>
										<Form.Group  className="form-group required control-label">
											<Form.Select className="dd" required name="accountType" value={this.state.accountType} onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Account Type</option>
												<option value="0">Promotional</option>
												<option value="1">Transactional</option>
											</Form.Select>
										</Form.Group>
									</Col>}
									{(this.props.app.role === 'ROLE_ADMIN') && <Col sm={4}>
										<Form.Group  className="form-group required control-label">
											<Form.Select className="dd" required name="accountType" value={this.state.accountType} onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Account Type</option>
												{(this.state.parentAccount === 0) && <option value="0">Promotional</option>}
												{(this.state.parentAccount === 1) && <option value="1">Transactional</option>}
											</Form.Select>
										</Form.Group>
									</Col>}
									<Col sm={4}>
										<Form.Group  className="form-group required control-label">
											<Form.Select className="dd" required name="planId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Voice Plan</option>
												{planDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
								
									{sendLaterDatepicker}
								</Row>
								{(this.props.app.role === 'ROLE_SADMIN') && <Row>
									<Col sm={5}>
										<Form.Group >
											<Form.Select required name="groupId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Group</option>
												{groupDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={1}>
										<i onClick={this.handleAddGroupRow} className="fa fa-plus-circle plus-icon"></i>
									</Col>
									<Col sm={5}>
										<Form.Group >
											<Form.Select required name="locationId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Location</option>
												{locationDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={1}>
										<i onClick={this.handleAddLocationRow} className="fa fa-plus-circle plus-icon"></i>
									</Col>
								</Row>}
								{(this.props.app.role === 'ROLE_SADMIN') && <Row>
									<Col sm={6}>
										<div className="react-bootstrap-table">

											<table className="table table-striped table-bordered table-sm widgetlist-table">
												<thead>
													<tr>
														<th>Group</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{groupsTable}
												</tbody>
											</table>
										</div>
									</Col>
									
									<Col sm={6}>
										<div className="react-bootstrap-table">

											<table className="table table-striped table-bordered table-sm widgetlist-table">
												<thead>
													<tr>
														<th>Location</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{locationsTable}
												</tbody>
											</table>
										</div>
									</Col>
								</Row>}
								<Row>
									<Col sm={12}>
										<Form.Group className='form-group'  controlId="Send-Logo">
											<Form.Check checked={this.state.logoDomain} onChange={( e ) => this.toggleChange( e, 'logoDomain' )} custom inline name="logoDomain" label="Send Logo and Domain" id="logoDomian" type="checkbox" />
										</Form.Group>
									</Col>
								</Row>
								{(this.state.logoDomain) && <Row>
									<Col sm={4}>
										<Form.Group  >
											<Form.Label>Logo</Form.Label>
											<Form.Control name="logo" onChange={this.onFileChangeHandler} ref={this.logoInput} type="file" />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group >
										<Form.Label>Domain</Form.Label>
											<Form.Control name="domain" type="text" placeholder="Domain" onChange={e => this.setvalue( e )} value={this.state.domain} />
										</Form.Group>
									</Col>
								</Row>}
								<Button className="btn-round" variant="primary" type="submit">Create User</Button>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Form>
		);
	}

}
export default CreateUser;