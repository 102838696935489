import React, { Component } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { cloneDeep, isEmpty } from 'lodash';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import { Redirect } from "react-router-dom";


// import "./index.css";
class UpdateDialer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dialerid: '',
			dialerName: '',
			dialerIP: '',
			dialerPort: '',
			channelReduction: '',
			cpsReduction: '',
			cps: '',
			totalChannels: '',
			origCps: '',
			origTotalChannels: '',
			activeCli: '',
			redirectToDialerManagement: false,
			trunkList: [],
			locationList: [],
			groupList: [],
			trunkId: '',
			locationId: '',
			groupId: '',
			groupName: '',
			groupRows: [],
			groupsList: '',
			trunkName: '',
			locationName: ''
		};

	}
	componentDidMount() {
		const apiPath = this.props.app.apiPath;
		const dialerId = this.props.selectedDialerId;
		Fetcher.get(apiPath + '/api/obd/trunks')
			.then(res => {
				this.setState({
					trunkList: res
				})
			})
			.catch(err => { console.log('Error in fetching Trunk List', err) });
		
		Fetcher.get(apiPath + '/api/obd/locations')
			.then(res => {
				this.setState({
					locationList: res
				})
			})
			.catch(err => { console.log('Error in fetching Location List', err) });
		
		Fetcher.get(apiPath + '/api/obd/groups')
			.then(res => {
				this.setState({
					groupList: res
				})
			})
			.catch(err => { console.log('Error in fetching Group List', err) });
		Fetcher.get(apiPath + '/api/obd/dialer/detail/' + dialerId)
			.then(res => {
				this.setState({
					dialerName: res.dialerObj.dialerName,
					dialerIP: res.dialerObj.dialerIP,
					dialerPort: res.dialerObj.dialerPort,
					channelReduction: res.dialerObj.channelReduction,
					cpsReduction: res.dialerObj.cpsReduction,
					groupRows: res.groups,
					trunkId: res.dialerObj.trunkId,
					trunkName: res.dialerObj.trunkName,
					locationId: res.dialerObj.locationId,
					locationName: res.dialerObj.locationName

				})
			})
			.catch(err => { console.log('Error in fetching Dialer data', err) });
	}

	handleAddGroupRow = () => {
		if (this.state.groupId !== '') {
			let groupName = this.state.groupId.substring(this.state.groupId.lastIndexOf(',')+1,this.state.groupId.length);
			let groupId = this.state.groupId.substring(0,this.state.groupId.lastIndexOf(','));
			const item = {
				groupId: groupId,
				groupName: groupName
			};
			const checkGroup = obj => obj.groupName === groupName;
			if(this.state.groupRows.some(checkGroup))
			{
					Notify.notify('This Group is already selected');
					return;
			}
			this.state.groupRows.push(item);
			this.setState({
				groupRows: this.state.groupRows
			});
			this.state.dialersList = {
				"groupsList": this.state.groupRows
			}
		}
		else {
			Notify.notify("Please select Group");
		}
	};

	handleRemoveGroupRow = () => {
		this.setState({
			groupRows: this.state.groupRows.slice(0, -1)
		});
	};

	handleChangeGroup = idx => e => {
		const { name, value } = e.target;
		const groupRows = [...this.state.groupRows];
		groupRows[idx] = {
			[name]: value
		};
		this.setState({
			groupRows
		});
	};

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}
	hideModal = () => {
		this.setState({
			redirectToDialerManagement: this.state.redirectToDialerManagement
		});
	}


	updateDialer = (event) => {
		event.preventDefault();
		const state = cloneDeep(this.state);
		if(state.groupRows.length === 0)
		{
			Notify.notify('Please select atleast one group');
			return;
		}
		if(state.groupsList === '')
		{
			this.state.groupsList = {
				"groupsList": this.state.groupRows
			}
		}
		const data = {
			dialerId: this.props.selectedDialerId,
			dialerName: state.dialerName,
			dialerIP: state.dialerIP,
			dialerPort: state.dialerPort,
			cpsReduction: state.cpsReduction,
			channelReduction: state.channelReduction,
			trunkId: state.trunkId,
			locationId: state.locationId,
			"groupRows": JSON.stringify(this.state.groupsList)
		}
		this.saveData(data);

	}
	saveData = (data) => {
		return Fetcher.post(this.props.app.apiPath + '/api/obd/dialer/update', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				let redirectToDialerManagement;
				if (res.message === 'Parameters Incorrect' || res.message === 'Request Failed!! Please try again') {
					Notify.notify(res.message);
					redirectToDialerManagement = false;
				} else {
					redirectToDialerManagement = true;
					Notify.notify(res.message);

				}
				this.setState({
					redirectToDialerManagement: redirectToDialerManagement
				})

			})
			.catch(err => {
				console.log('Error in Updating Dialer', err);
				Notify.notify('Error in Updating Dialer');
				
			});

	}

	render() {
		if (this.state.redirectToDialerManagement === true) {
			return <Redirect to={'/dialer-list/'} />
		}

		const trunkList = cloneDeep(this.state.trunkList);
		let trunkDropdown = '';
		if (!isEmpty(trunkList) && (!('error' in trunkList))) {
			trunkDropdown = Array.isArray(trunkList)?trunkList.map((obj, index) => {
				return <option value={obj.trunkId} key={`trunk${index}`} >{obj.trunkName}</option>
			}) : []

		} else {
			trunkDropdown = <option value="" >No trunk found</option>
		}

		const locationList = cloneDeep(this.state.locationList);
		let locationDropdown = '';
		if (!isEmpty(locationList) && (!('error' in locationList))) {
			locationDropdown = Array.isArray(locationList) ? locationList.map((obj, index) => {
				return <option value={obj.locationId} key={`location${index}`} >{obj.locationName}</option>
			}) : []

		} else {
			locationDropdown = <option value="" >No location found</option>
		}

		const groupList = cloneDeep(this.state.groupList);
		let groupDropdown = '';
		if (!isEmpty(groupList) && (!('error' in groupList))) {
			groupDropdown = Array.isArray(groupList) ? groupList.map((obj, index) => {
				return <option value={obj.groupId+','+obj.groupName} key={`group${index}`} >{obj.groupName}</option>
			}) : []

		} else {
			groupDropdown = <option value="" >No group found</option>
		}

		const groupRows = cloneDeep(this.state.groupRows);
		const allocatedGroupTable = Array.isArray(groupRows) ? groupRows.map((item, index) => {
			return <tr key={index}>
				<td>{groupRows[index].groupName}</td>
				<td>
					<i onClick={() => this.handleRemoveGroupRow()} className="fa fa-minus-circle"></i>
				</td>
			</tr>;
		}) : [];

		return (

			<Form method="post" onSubmit={this.updateDialer}>
				<Row>
					<Col sm={12}>
						<Card>
						<Card.Header>Update Dialer</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={3}>
										<Form.Group className='form-group'>
										<Form.Label>Dialer Name</Form.Label>
											<Form.Control required name="dialerName" type="text" placeholder="Dialer Name" onChange={e => this.setvalue(e)} value={this.state.dialerName} />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group className='form-group'>
											<Form.Label>Dialer IP</Form.Label>
											<Form.Control required name="dialerIP" type="text" placeholder="IP" onChange={e => this.setvalue(e)} value={this.state.dialerIP} />
										</Form.Group>
									</Col>
									<Col sm={2}>
										<Form.Group className='form-group'>
											<Form.Label>Dialer Port</Form.Label>
											<Form.Control required name="dialerPort" type="text" placeholder="Port" onChange={e => this.setvalue(e)} value={this.state.dialerPort} />
										</Form.Group>
									</Col>
									<Col sm={2}>
										<Form.Group className='form-group'>
										<Form.Label>Channel Reduction</Form.Label>
											<Form.Control required name="channelReduction" type="text" placeholder="Channel Reduction" onChange={e => this.setvalue(e)} value={this.state.channelReduction} />
										</Form.Group>
									</Col>
									<Col sm={2}>
										<Form.Group className='form-group'>
											<Form.Label>CPS Reduction</Form.Label>
											<Form.Control required name="cpsReduction" type="text" placeholder="cpsReduction" onChange={e => this.setvalue(e)} value={this.state.cpsReduction} />
										</Form.Group>
									</Col>
									<Col sm={11}>
										<Form.Group className='form-group'>
											<Form.Select name="groupId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Group</option>
												{groupDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={1}>
										<Form.Group className='form-group'>
											<i onClick={this.handleAddGroupRow} className="fa fa-plus-circle plus-icon"></i>
										</Form.Group>
									</Col>
									
									<Col sm={12}>
										<div className="react-bootstrap-table">

											<table className="table table-striped table-bordered table-sm widgetlist-table">
												<thead>
													<tr>
														<th>Group</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{allocatedGroupTable}
												</tbody>
											</table>
										</div>
									</Col>
								
									<Col sm={6}>
										<Form.Label>Trunk</Form.Label>
										<Form.Group className='form-group'>
											<Form.Select required name="trunkId" onChange={e => this.setvalue(e)} as="select">
											<option value={this.state.trunkId}>{this.state.trunkName}</option>
												{trunkDropdown}
											</Form.Select>
										</Form.Group>
									</Col>						
									<Col sm={6}>
										<Form.Label>Location</Form.Label>
										<Form.Group className='form-group'>
											<Form.Select required name="locationId" onChange={e => this.setvalue(e)} as="select">
												<option value={this.state.locationId}>{this.state.locationName}</option>
												{locationDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">Update Dialer</Button>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Form>
		);
	}

}
export default UpdateDialer;