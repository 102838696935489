import React, { Component } from 'react';
import { Form, Row, Col, Card, Button, Modal, Tabs, Tab } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Fetcher } from "../../../Helpers/fetcher.js";
import { cloneDeep, isEmpty } from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

class VoiceCampaignInfo extends Component {
	constructor(props) {
		super(props);

		this.baseFileInput = React.createRef();
		let sitePath = '';
		let filePath = '';

		const protocol = window.location.protocol;
		const slashes = protocol.concat("//");
		sitePath = slashes.concat(window.location.hostname);

		this.state = {
			campaignPrompts: [],
			campaignAgents: [],
			campaignSms: [],
			campaignName: '',
			templateId: '0',
			dtmf: '',
			agentNumber: '',
			agentDtmf: '',
			userId: props.app.userId,
			apiPath: props.app.apiPath,
			sitePath: sitePath,
			filePath: filePath,
			promptId: '',
			welcomeId: '',
			menuId: '',
			noinputId: '',
			wronginputId: '',
			thanksId: '',
			redirectToSummary: false,
			campaignCreated: false,
			smsLink: '',
			smsApiSuccess: '',
			smsApiFail: '',
			smsApiDtmf: '',
			callDurationSMS: 0,
			trunk: '',
			channels: '',
			menuWaitTime: '',
			rePrompt: ''
		}
	}

	componentDidMount() {
		const campaignId = this.props.campaignId;
		const apiPath = this.state.apiPath;

		// Get Campaign Info
		Fetcher.get(apiPath + '/api/obd/campaign/info/' + campaignId)
			.then(res => {
				
				this.setState({
					campaignName: res.campaign.campaignName,
					validDtmf: res.campaign.dtmf,
					templateId: res.campaign.templateId,
					channels: res.campaign.channels,
					menuWaitTime: res.campaign.menuWaitTime,
					campaignPrompts: res.campaignPrompts,
					campaignAgents: res.campaignAgents,
					campaignSms: res.campaignSms,
				})
			})
			.catch(err => { console.log('Error in fetching BaseUpload Files', err) });
	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	render() {
		// Redirect to Summary if Campaign Added successfully.
		if (this.state.redirectToSummary === true) {
			return <Redirect to='/voice-campaign-summary' />
		}
		//console.log( 'Add Campaign State', this.state );
		const campaignPrompts = cloneDeep(this.state.campaignPrompts);
		const campaignAgents = cloneDeep(this.state.campaignAgents);
		const campaignSms = cloneDeep(this.state.campaignSms);
		let promptsInfo = '';

		if (!isEmpty(campaignPrompts) && (!('error' in campaignPrompts))) {
			promptsInfo = Array.isArray(campaignPrompts) ? campaignPrompts.map((fileObj, index) => {
				return <Col sm={4}>
				<Form.Group className='form-group'>
					<Form.Label>Prompt Info</Form.Label>
					<Form.Control readOnly value={'Name: '+fileObj.promptName} type="text" />
					<Form.Control readOnly value={'Duration: '+fileObj.promptDuration} type="text" />
				</Form.Group>
			</Col>
			}) : []

		} else {
			promptsInfo = '';
		}

		let smsInfo = '';
		if (!isEmpty(campaignSms) && (!('error' in campaignSms))) {
			smsInfo = Array.isArray(campaignSms) ? campaignSms.map((fileObj, index) => {
				return <Col sm={12}>
				<Form.Group className='form-group'>
					<Form.Label>SMS Info</Form.Label>
					<Form.Control readOnly value={'Event: '+fileObj.smsEvent} type="text" />
					<Form.Control readOnly value={'SMS API: '+fileObj.smsText} type="text" />
				</Form.Group>
			</Col>
			}) : []

		} else {
			smsInfo = '';
		}
		let agentInfo = '';
		if (!isEmpty(campaignAgents) && (!('error' in campaignAgents))) {
			agentInfo = Array.isArray(campaignAgents) ? campaignAgents.map((fileObj, index) => {
				return <Col sm={12}>
				<Form.Group className='form-group'>
					<Form.Label>Agent Info</Form.Label>
					<Form.Control readOnly value={'Agent No.: '+fileObj.agentNumber} type="text" />
					<Form.Control readOnly value={'Agent DTMF: '+fileObj.agentDtmf} type="text" />
				</Form.Group>
			</Col>
			}) : []

		} else {
			agentInfo = '';
		}
		
		var template = '';
		switch (this.state.templateId) {
			case 0:
				template = 'Simple IVR';
				break;
			case 1:
				template = 'DTMF';
				break;
			case 2:
				template = 'Call Patch';
				break;
			default:
				template = '';
				break;
		}

		return (

			<Form className="add-campaign-form" onSubmit={(e) => this.handleAddCampaignSubmit(e)}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Voice Campaign Info</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={4}>
										<Form.Group className='form-group' controlId="campaign-name">
											<Form.Label>Campaign Name</Form.Label>
											<Form.Control readOnly value={this.state.campaignName} type="text" />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className='form-group' >
											<Form.Label>Campaign Type</Form.Label>
											<Form.Control readOnly value={template} type="text" />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className='form-group' >
											<Form.Label>Valid DTMF</Form.Label>
											<Form.Control readOnly value={this.state.validDtmf} type="text" />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={4}>
										<Form.Group className='form-group'>
											<Form.Label>Menu Wait Time</Form.Label>
											<Form.Control readOnly value={this.state.menuWaitTime} type="text" />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className='form-group'>
											<Form.Label>Channels</Form.Label>
											<Form.Control readOnly value={this.state.channels} type="text" />
										</Form.Group>
									</Col>
								</Row>
								<Row>{promptsInfo}</Row>
								<Row>{smsInfo}</Row>
								<Row>{agentInfo}</Row>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Form>
		);
	}
}

export default VoiceCampaignInfo;