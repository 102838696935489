import React, { Component } from 'react';
import { Form, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import { cloneDeep, isEmpty } from 'lodash';
import "./index.css";

class AddVoiceBlacklist extends Component {
	constructor(props) {
		super(props);
		let sitePath;
		let filePath;
		
		const protocol = window.location.protocol;
		const slashes = protocol.concat("//");
		sitePath = slashes.concat(window.location.hostname);
		this.state = {
			userId: props.app.userId,
			apiPath: props.app.apiPath,
			sitePath: sitePath,
			filePath: filePath,
			blacklistAdded: false,
			mobile: ''
		}

	}

	componentDidMount() {

	}

	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
	}


	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	setMessageShow = (status) => {
		this.setState({
		});
	}

	// Add Plan
	handleAddVoiceBlacklist = (event) => {
		event.preventDefault();
		const formData = cloneDeep(this.state);
		console.log(formData);

		const data = {
			"userId": formData.userId,
			"mobile": formData.mobile
		};

		return Fetcher.post(formData.apiPath + '/api/obd/blacklist', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				
				
				let blacklistAdded = false;
				if (res.message === 'Parameters Incorrect') {
					Notify.notify(res.message);
				} else {
					Notify.notify(res.message);
					blacklistAdded = true;
				}
				this.setState({
					blacklistAdded: blacklistAdded
				})
			})
			.catch(err => {
				console.log('Error in Creating Blacklist', err);
				Notify.notify('Error in Creating Blacklist');
			});
	}

	addZero = (i) => {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}

	render() {
		if (this.state.blacklistAdded === true) {
			return <Redirect to='/voice-blacklist' />
		}
		let submitButtonlabel = 'Add to Blacklist';

		return (

			<Form className="add-campaign-form" onSubmit={(e) => this.handleAddVoiceBlacklist(e)}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Add Number to Blacklist</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={4}>
										<Form.Group className='form-group' controlId="plan-name">
											<Form.Label>Mobile No.</Form.Label>
											<Form.Control required name="mobile" onChange={e => this.setvalue(e)} value={this.state.mobile} type="number" placeholder="Mobile No." />
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">{submitButtonlabel}</Button>
							</Card.Body>
						</Card>

					</Col>
				</Row>
			</Form>
		);
	}
}

export default AddVoiceBlacklist;