import React, { Component } from 'react';
import { Form, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import { cloneDeep, isEmpty } from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

class CreateDialer extends Component {
	constructor(props) {
		super(props);

		
		let sitePath;
		let filePath;
		
		const protocol = window.location.protocol;
		const slashes = protocol.concat("//");
		sitePath = slashes.concat(window.location.hostname);
		this.state = {
			apiPath: props.app.apiPath,
			sitePath: sitePath,
			filePath: filePath,
			dialerCreated: false,
			dialerName: '',
			dialerIP: '',
			dialerPort: '',
			trunkList: [],
			locationList: [],
			groupList: [],
			trunkId: '',
			locationId: '',
			groupId: '',
			groupName: '',
			groupRows: [],
			groupsList: ''
		}

	}

	componentDidMount() {
		const apiPath = this.props.app.apiPath;

		Fetcher.get(apiPath + '/api/obd/trunks')
			.then(res => {
				this.setState({
					trunkList: res
				})
			})
			.catch(err => { console.log('Error in fetching Trunk List', err) });
		
		Fetcher.get(apiPath + '/api/obd/locations')
			.then(res => {
				this.setState({
					locationList: res
				})
			})
			.catch(err => { console.log('Error in fetching Location List', err) });
		
		Fetcher.get(apiPath + '/api/obd/groups')
			.then(res => {
				this.setState({
					groupList: res
				})
			})
			.catch(err => { console.log('Error in fetching Group List', err) });
	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	setMessageShow = (status) => {
		const dialerCreated = this.state.dialerCreated;
		this.setState({
			dialerCreated: dialerCreated
		});
	}

	handleAddGroupRow = () => {
		if (this.state.groupId !== '') {
			let groupName = this.state.groupId.substring(this.state.groupId.lastIndexOf(',')+1,this.state.groupId.length);
			let groupId = this.state.groupId.substring(0,this.state.groupId.lastIndexOf(','));
			const item = {
				groupId: groupId,
				groupName: groupName
			};
			const checkGroup = obj => obj.groupId === groupId;
			if(this.state.groupRows.some(checkGroup))
			{
					Notify.notify('This Group is already selected');
					return;
			}
			this.state.groupRows.push(item);
			this.setState({
				groupRows: this.state.groupRows
			});
			this.state.groupsList = {
				"groupsList": this.state.groupRows
			}
		}
		else {
			Notify.notify("Please select Group");
		}
	};

	handleRemoveGroupRow = () => {
		this.setState({
			groupRows: this.state.groupRows.slice(0, -1)
		});
	};

	handleChangeGroup = idx => e => {
		const { name, value } = e.target;
		const groupRows = [...this.state.groupRows];
		groupRows[idx] = {
			[name]: value
		};
		this.setState({
			groupRows
		});
	};

	// Create Dialer
	handleCreateDialerSubmit = async (event) => {
		event.preventDefault();
		const formData = cloneDeep(this.state);
		
		const data = {
			"dialerName": formData.dialerName,
			"dialerIP": formData.dialerIP,
			"dialerPort": formData.dialerPort,
			"trunkId": formData.trunkId,
			"locationId": formData.locationId,
			"groupRows": JSON.stringify(this.state.groupsList)
		}
		this.addNewDialer(data);
	}

	addNewDialer = (data) => {
		console.log(data);
		return Fetcher.post(this.state.apiPath + '/api/obd/dialer', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				let dialerCreated;
				if (res.message === 'Parameters Incorrect' || 'Dialer Name already exists') {
					Notify.notify(res.message);
				} else {
					dialerCreated = true;
					Notify.notify(res.message);
				}
				this.setState({
					dialerCreated: dialerCreated
				})
			})
			.catch(err => {
				console.log('Error in Creating Dialer', err);
				Notify.notify('Error in Creating Dialer');
				
			});
	}

	render() {
		if (this.state.dialerCreated === true) {
			return <Redirect to={'/dialer-list/'} />
		}

		const groupRows = cloneDeep(this.state.groupRows);
		const groupsTable = Array.isArray(groupRows) ? groupRows.map((item, index) => {
			return <tr key={index}>
				<td>{this.state.groupRows[index].groupName}</td>
				<td>
					<i onClick={() => this.handleRemoveGroupRow()} className="fa fa-trash text-danger"></i>
				</td>
			</tr>;
		}) : [];

		const trunkList = cloneDeep(this.state.trunkList);
		let trunkDropdown = '';
		if (!isEmpty(trunkList) && (!('error' in trunkList))) {
			trunkDropdown = Array.isArray(trunkList)?trunkList.map((obj, index) => {
				return <option value={obj.trunkId} key={`trunk${index}`} >{obj.trunkName}</option>
			}) : []

		} else {
			trunkDropdown = <option value="" >No trunk found</option>
		}

		const locationList = cloneDeep(this.state.locationList);
		let locationDropdown = '';
		if (!isEmpty(locationList) && (!('error' in locationList))) {
			locationDropdown = Array.isArray(locationList) ? locationList.map((obj, index) => {
				return <option value={obj.locationId} key={`location${index}`} >{obj.locationName}</option>
			}) : []

		} else {
			locationDropdown = <option value="" >No location found</option>
		}

		const groupList = cloneDeep(this.state.groupList);
		let groupDropdown = '';
		if (!isEmpty(groupList) && (!('error' in groupList))) {
			groupDropdown = Array.isArray(groupList) ? groupList.map((obj, index) => {
				return <option value={obj.groupId+','+obj.groupName} key={`group${index}`} >{obj.groupName}</option>
			}) : []

		} else {
			groupDropdown = <option value="" >No group found</option>
		}

		let submitButtonlabel = 'Create Dialer';

		return (

			<Form className="add-campaign-form" onSubmit={(e) => this.handleCreateDialerSubmit(e)}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Create Dialer</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={4}>
										<Form.Group className='form-group'>
											<Form.Control required name="dialerName" type="text" placeholder="Dialer Name" onChange={e => this.setvalue(e)} value={this.state.dialerName} />
										</Form.Group>
									</Col>
								
									<Col sm={4}>
										<Form.Group className='form-group'>
											<Form.Control required name="dialerIP" type="text" placeholder="IP" onChange={e => this.setvalue(e)} value={this.state.dialerIP} />
										</Form.Group>
									</Col>
								
									<Col sm={4}>
										<Form.Group className='form-group'>
											<Form.Control required name="dialerPort" type="text" placeholder="Port" onChange={e => this.setvalue(e)} value={this.state.dialerPort} />
										</Form.Group>
									</Col>
								
									<Col sm={11}>
										<Form.Group className='form-group'>
											<Form.Select required name="groupId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Group</option>
												{groupDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={1}>
										<Form.Group className='form-group'>
											<i onClick={this.handleAddGroupRow} className="fa fa-plus-circle plus-icon"></i>
										</Form.Group>
									</Col>
									
									<Col sm={12}>
										<div className="react-bootstrap-table">

											<table className="table table-striped table-bordered table-sm widgetlist-table">
												<thead>
													<tr>
														<th>Group</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{groupsTable}
												</tbody>
											</table>
										</div>
									</Col>
								
									<Col sm={6}>
										<Form.Group className='form-group'>
											<Form.Select required name="trunkId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Trunk</option>
												{trunkDropdown}
											</Form.Select>
										</Form.Group>
									</Col>						
									<Col sm={6}>
										<Form.Group className='form-group'>
											<Form.Select required name="locationId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Location</option>
												{locationDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">{submitButtonlabel}</Button>
							</Card.Body>
						</Card>

					</Col>
				</Row>
			</Form>
		);
	}
}

export default CreateDialer;