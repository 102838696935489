import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Button, Modal, Card } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Fetcher } from "../../Helpers/fetcher.js";
import { Notify } from "../../Helpers/notification.js";
import ReactTooltip from 'react-tooltip';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import "./index.css";
class GroupManagement extends Component {
	constructor(props) {

		super(props);
		this.state = {
			groupList: [],
			confirmDelete: false,
			selectedGroupId: 0,
			showAddCreditModal: false
		}
	}
	componentDidMount() {
		this.getGroup();
	}


	getGroup = () => {
		const apiPath = this.props.app.apiPath;

		Fetcher.get(apiPath + '/api/obd/groups')
			.then(res => {
				this.setState({
					groupList: res,
					showAddCreditModal: false,
					confirmDelete: false,
				})
			})
			.catch(err => { console.log('Error in fetching Group List', err) });
	}

	confirmDelete = (status, groupId) => {
		this.setState({
			confirmDelete: status,
			selectedGroupId: groupId
		});
	}

	deleteGroup = () => {
		const groupId = this.state.selectedGroupId;
		
		const data = {
			
		}

		return Fetcher.post(this.props.app.apiPath + '/api/obd/group/'+groupId, { headers: { "Content-type": "application/json" }, method: 'DELETE', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getGroup();
			})
			.catch(err => {
				console.log('Error in Deleting Group', err);
				Notify.notify('Error in Deleting Group');
			});

	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	setMessageShow = (status) => {
		this.setState({
			showAddCreditModal: false,
		});
	}


	render() {

		const groupList = this.state.groupList;
		var columns;
			columns = [
				{
					dataField: 'groupId',
					text: 'Group Id',
					sort: false,
					hidden: true
				},
				{
					dataField: 'groupName',
					text: 'Group Name',
					sort: true,
					searchable: true
				},
				{
					dataField: 'reqDate',
					text: 'Request Date',
					sort: true,
					searchable: true
				},
				{
					dataField: 'action',
					text: 'Action',
					sort: false,
					searchable: false
				},];
		
		const group = Array.isArray(groupList) ? groupList.map((groups, index) => {
				let action = [ <Link data-tip="Edit Group" key={`${index}-edit-group`} className="edit-group" to={`${this.props.app.urlPrefix}/update-group/${groups.groupId}`}><i className="fa fa-edit"></i></Link> ];
				action.push(<a data-tip="Delete Group" key={`${index}-delete-group`} className="delete-group" onClick={(e) => { this.confirmDelete(true, groups.groupId) }} href="#"><i className="fa fa-trash"></i></a>);
				return {
					groupId: groups.groupId,
					groupName: groups.groupName,
					reqDate: groups.reqDate,
					action: action
				};
			
		}) : []
		const { SearchBar, ClearSearchButton } = Search;

		const defaultSorted = [{
			dataField: 'groupId',
			order: 'desc'
		}];

		const paginationOption = {
			custom: true,
			totalSize: group.length,
			sizePerPage: 10,
			sizePerPageList: [10, 25, 50, 100]
		};

		return (
			<Card>
				{/*<Card.Header>Users List<span>&nbsp;&nbsp;&nbsp;</span><span className="float-none avail-credit-cont"><span className="credits-avail">Available Credits: {this.state.adminCredits}</span></span></Card.Header>*/}
				<Card.Header>Group List</Card.Header>
				<Card.Body>
					<div className="widget-list-table-cont">
						{/* <h3>Campaign Summary</h3> */}
						{/*{!isEmpty( this.state.groupList ) && <PaginationProvider pagination={paginationFactory( paginationOption )}>*/}
						{<PaginationProvider pagination={paginationFactory(paginationOption)}>
							{
								({
									paginationProps,
									paginationTableProps
								}) => (
									<ToolkitProvider
										keyField="groupId"
										data={group}
										columns={columns}
										search
										bootstrap4

									>
										{
											props => (
												<div className="">
													Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
													<span className="search-cont"><SearchBar  {...props.searchProps} /></span>
													<ClearSearchButton className="btn-primary btn-round" {...props.searchProps} />
													{/*<span>&nbsp;&nbsp;&nbsp;</span><span className="float-none avail-credit-cont"><span className="credits-avail">Available Credits: {this.state.adminCredits}</span></span>*/}
													{/*<Link className="float-right btn btn-default btn-primary" to={`${this.props.app.urlPrefix}/add-cli`}>Allocate CLI</Link>*/}
													<hr />
													<BootstrapTable defaultSorted={defaultSorted} bordered={true} hover condensed wrapperClasses="table-responsive" classes="widgetlist-table"  {...props.baseProps}  {...paginationTableProps} />
													<PaginationListStandalone {...paginationProps} />
													<ReactTooltip />
												</div>
											)
										}
									</ToolkitProvider>
								)
							}
						</PaginationProvider>}
					</div>

					<Modal size="md" show={this.state.confirmDelete} onHide={() => this.confirmDelete(false, '')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Delete Group
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to delete this Group?
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={() => this.confirmDelete(false, '')} variant="secondary">CANCEL</Button>
							<Button onClick={this.deleteGroup} variant="primary">YES</Button>
						</Modal.Footer>
					</Modal>

				</Card.Body>
			</Card>

		);
	}
}

export default GroupManagement;